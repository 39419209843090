<template>
  <header
    :class="{
      'flex justify-center border-b-2 border-b-neutral-200 bg-light px-4 py-3 shadow-sm md:space-y-8 lg:border-none lg:bg-neutral-50 lg:p-8 lg:shadow-none': true,
      'lg:hidden': !showOnDesktop,
    }"
  >
    <div class="container flex flex-row justify-between">
      <rb-link
        v-if="showBackButton"
        :href="backLink"
        class="lg:hidden"
        size="sm"
        type="neutral"
        leading-icon-name="arrow-left"
        @click.prevent="navigateTo(backLink)"
      >
        {{ $t('header.back') }}
      </rb-link>

      <nuxt-link v-else class="place-self-center lg:hidden" to="/">
        <rb-logo />
      </nuxt-link>
      <rb-breadcrumb
        v-if="showOnDesktop"
        class="hidden lg:flex"
        :pages="pages"
        @navigate-to-page="(href: string) => navigateTo(href)"
      />
      <div class="flex items-center space-x-4">
        <base-user-profile-picture class="lg:hidden" />

        <rb-button
          class="lg:hidden"
          size="md"
          hierarchy="tertiary"
          type="neutral"
          icon-name="menu"
          :alt-text="$t('header.menu_button.alt_text')"
          @click="layoutStore.toggleSidebar()"
        />
        <rb-tooltip
          v-if="showOnDesktop"
          v-feature="'BoxPages'"
          :title="$t('header.button.migrate_wordpress.tooltip')"
        >
          <rb-button
            class="hidden lg:inline-flex"
            hierarchy="secondary"
            size="sm"
            @click="onMigrate"
          >
            {{ $t('header.button.migrate_wordpress') }}
          </rb-button></rb-tooltip
        >
        <rb-button
          v-if="showOnDesktop"
          v-feature="'BoxPages'"
          class="hidden lg:inline-flex"
          hierarchy="primary"
          size="sm"
          @click="() => navigateTo('/boxes/create-new')"
        >
          {{ $t('header.button.create_new_box') }}
        </rb-button>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const layoutStore = useLayoutStore();

const startpageStore = useStartpageStore();
const {
  showRedirectInfoModal,
  doNotShowRedirectInfoModalAgain,
  classicDashboardUrl,
  primaryButtonText,
} = storeToRefs(startpageStore);
const route = useRoute();
const router = useRouter();

const { t: $t } = useI18n();

defineProps({
  showOnDesktop: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  showBackButton: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  backLink: {
    type: String as PropType<string>,
    default: '/',
  },
});

const pages = computed<IPage[]>(() => {
  const { fullPath } = route;

  const modPath = fullPath.endsWith('/') ? fullPath.slice(0, -1) : fullPath;
  const params = modPath.startsWith('/') ? modPath.substring(1).split('/') : modPath.split('/');

  const crumbs = [] as IPage[];

  crumbs.push({
    id: 0,
    name: $t('dashboard.title'),
    href: '/',
    current: false,
  });

  let path = '';

  params.forEach((param, index) => {
    path = `${path}/${param}`;
    const match = router.resolve(path);

    if (match.name !== null) {
      if (match.meta.title !== undefined) {
        crumbs.push({
          id: index,
          name: $t(match.meta.title as string),
          href: path,
          current: path === fullPath,
        });
      }
    }
  });

  return crumbs;
});

const onMigrate = () => {
  classicDashboardUrl.value = 'migration-for-wp-site';
  primaryButtonText.value = 'migration';

  if (!doNotShowRedirectInfoModalAgain.value) {
    showRedirectInfoModal.value = true;
  } else {
    startpageStore.redirectToClassicDashboard();
  }
};
</script>
<script lang="ts">
export default {
  name: 'BaseHeader',
};
</script>
